import React, { useState, useEffect } from "react"
import SearchBlock from "./searchBlock"
import config from "../../config"
import { getSuggestions } from "../../api/content"
import FilterSearchButton from "../buttons/FilterSearchButton.js"
import SignInModal from "../modals/SignInModal"
import PaymentMethodModal from "../modals/PaymentMethodModal"
import NewPaymentFormModal from "../modals/NewPaymentFormModal"
import SubscriptionMessageModal from "../modals/SubscriptionMessageModal"
import CvvModal from "../forms/payments/cvvModal"
import PerfilForm from "../modals/PerfilFormModal"
import { getSearch } from "../../api/content" // Importa la función getSearch desde tu API
import PremiumButton from "../../components/buttons/PremiumButton"

const Section = ({ section, userHasSuscription, suscription, location }) => {
  const [sectionId] = useState(section.nombre)
  const [suggestions, setSuggestions] = useState(null)
  const [SectionFiltrada, setSectionFiltrada] = useState(null)
  const [showSignInForm, setShowSignInForm] = useState(false)
  const [showPaymentMethod, setShowPaymentMethod] = useState(false)
  const [showNewPayment, setShowNewPayment] = useState(false)
  const [showSuccessful, setShowSuccessful] = useState(false)
  const [showCvvHelp, setShowCvvHelp] = useState(false)
  const [showPerfilForm, setShowPerfilForm] = useState(false)
  const [ispwa, setIspwa] = useState(null)
  const [isApplePay, setIsApplePay] = useState(false)
  const [filteredCards, setFilteredCards] = useState([])
  const [bloqueFiltrado, setBloqueFiltrado] = useState(["null"])
  const [isLoading, setIsLoading] = useState(false)

  const queryString = location.search
  const searchParams = new URLSearchParams(queryString)
  const initialSearchTerm = searchParams.get("term") || ""
  const searchSection = searchParams.get("sectionId") || ""
  console.log(section)

  const handleFilter = nuevoBloque => {
    setFilteredCards(nuevoBloque)
  }

  React.useEffect(() => {
    setIsLoading(true) // Indicar que la petición está en curso
    getSearch(initialSearchTerm, searchSection)
      .then(data => {
        // Actualiza los resultados en función de la respuesta
        setSectionFiltrada(data)
        setIsLoading(false) // Indicar que la petición ha terminado
      })
      .catch(error => {
        console.error("Error al obtener los datos filtrados:", error)
        setIsLoading(false) // Indicar que la petición ha terminado (incluso si hay un error)
      })
  }, [initialSearchTerm])

  return (
    <div>
      <header>
        <div>
          <div className="flex relative" style={{ height: 240, width: "100%" }}>
            {section[0] && section[0].portrait && section[0].portrait.url ? (
              <img
                className="absolute"
                src={
                  section[0].portrait.url.trim().startsWith("http")
                    ? section[0].portrait.url.trim()
                    : config.serverUri + section[0].portrait.url.trim()
                }
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                alt={section[0].name}
              />
            ) : (
              <img
                className="absolute"
                src={
                  section[0].seccion.portrait.url.trim().startsWith("http")
                    ? section[0].seccion.portrait.url.trim()
                    : config.serverUri + section[0].seccion.portrait.url.trim()
                }
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                alt={section[0].name}
              />
            )}

            <div
              className={`absolute flex flex-col  md:justify-end  md:p-5  items-start h-full ${
                section.nombre === "Home" && userHasSuscription !== "premium"
                  ? "px-5 justify-start"
                  : "p-5 justify-end"
              }`}
            >
              <h1 className="text-white">Shift</h1>
            </div>

            {section.nombre === "Search" && userHasSuscription !== "premium" ? (
              <div
                className="absolute flex justify-center "
                style={{ height: 240, width: "100%" }}
              >
                <div className="flex flex-col justify-items-stretch content-end h-full p-8 mt-2">
                  <p className="text-white text-center text-2xl mb-0">
                    Prueba gratis por 7 días
                  </p>
                  <p className="text-white text-center text-lg mb-0">
                    Disfruta de contenido ilimitado. ¿Qué esperas?
                  </p>
                  <PremiumButton
                    href="#"
                    onClick={() => {
                      userHasSuscription !== "free" &&
                      userHasSuscription !== null
                        ? setShowSignInForm(true)
                        : setShowPaymentMethod(true)
                    }}
                  >
                    Hazte premium
                  </PremiumButton>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </header>
      <main className="p-8 mb-32">
        <div className="filters-section">
          <FilterSearchButton
            section={SectionFiltrada}
            message={
              isLoading
                ? "Cargando resultados..."
                : SectionFiltrada
                ? SectionFiltrada.length > 0
                  ? `Se encontraron ${SectionFiltrada.length} resultados de: ${initialSearchTerm}`
                  : `No hubo resultados para: ${initialSearchTerm}`
                : `No hubo resultados para: ${initialSearchTerm}`
            }
            onSectionFilterChange={e => handleFilter(e)}
          />
        </div>

        {filteredCards.length !== 0 ? (
          <>
            <SearchBlock
              key={1}
              subtree={filteredCards.isBlock}
              sectionId={filteredCards.id}
              userHasSuscription={userHasSuscription ?? null}
              cards={filteredCards} // Pasa las cards como prop
            ></SearchBlock>
          </>
        ) : null}

        {filteredCards.length === 0 ? (
          <>
            <SearchBlock
              subtree={filteredCards.isBlock}
              block={suggestions}
              sectionId={sectionId}
              userHasSuscription={userHasSuscription ?? null}
              cards={SectionFiltrada} // Pasa las cards como prop
            />
          </>
        ) : null}
      </main>
      <SignInModal
        show={showSignInForm}
        onClose={() => {
          setShowSignInForm(false)
        }}
      />
      <PaymentMethodModal
        show={showPaymentMethod}
        onClose={() => {
          setShowPaymentMethod(false)
        }}
        newPaymentMethod={value => {
          setShowNewPayment(value)
          setShowPaymentMethod(false)
        }}
        message={value => {
          setShowSuccessful(value)
          suscription("premium")
          setShowPaymentMethod(false)
        }}
        setIsApplePay={setIsApplePay}
      />
      <NewPaymentFormModal
        show={showNewPayment}
        onClose={() => {
          setShowNewPayment(false)
        }}
        back={() => {
          setShowPaymentMethod(true)
          setShowNewPayment(false)
        }}
        cvvHelp={() => setShowCvvHelp(true)}
        messageOk={value => {
          suscription("premium")
          setShowSuccessful(value)
          setShowNewPayment(false)
        }}
      />
      <SubscriptionMessageModal
        isApplePay={isApplePay}
        show={showSuccessful}
        onClose={() => {
          setShowSuccessful(false)
          setShowPerfilForm(true)
        }}
      />
      <CvvModal show={showCvvHelp} onClose={() => setShowCvvHelp(false)} />

      {!ispwa && userHasSuscription === "premium" ? (
        <PerfilForm
          show={showPerfilForm}
          ispwaComponent={ispwa}
          onClose={() => {
            setShowPerfilForm(false)
          }}
        />
      ) : null}
    </div>
  )
}

export default Section
